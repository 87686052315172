<template>
  <TransitionGroup v-show="alerts.length" class="AlertGroup" tag="div" name="alerts">
    <Alert v-for="alert in alerts" :key="alert.id" v-bind="alert" @dismiss="dismiss" />
  </TransitionGroup>
</template>

<script setup lang="ts">
const { alerts, dismiss } = useAlerts();
</script>

<style lang="postcss">
.AlertGroup {
  @apply fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 99999;
}

.Alert + .Alert {
  margin-top: 1rem;
}

@screen lg {
  .AlertGroup {
    top: unset;
    left: unset;
    bottom: 10rem;
    right: 2rem;
  }
}

.alerts-enter-active,
.alerts-leave-active {
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}

.alerts-enter,
.alerts-leave-to {
  transform: translate3d(200%, 0, 0);
}
</style>
