<template>
  <div
    class="Alert"
    :class="{
      'Alert--success': type === 'success',
      'Alert--danger': type === 'danger',
      'Alert--info': type === 'info',
      'Alert--warning': type === 'warning',
    }"
  >
    <!-- <component :is="icon" class="w-6 h-6" /> -->
    <div class="ml-4 Alert__Body">
      <p class="text-primary-1-100 font-bold" role="alert" :aria-live="type === 'danger' ? 'assertive' : 'polite'">
        {{ title }}
      </p>
      <p class="text-sm" role="alert" :aria-live="type === 'danger' ? 'assertive' : 'polite'">
        {{ subtitle }}
      </p>
    </div>
    <button class="ml-24 flex-center self-start button" @click="dismiss">
      <svg-icon-close class="stroke-current text-primary-1-100 w-5 h-5" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['dismiss']);

function dismiss() {
  emit('dismiss', props.id);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const icon = computed(() => {
  return resolveComponent(
    'svg-icon-' +
      (
        {
          danger: 'error',
          success: 'check',
          info: 'check',
          warning: 'error',
        } as any
      )[props.type],
  );
});
</script>

<style lang="postcss" scoped>
.Alert {
  @apply bg-white shadow-custom p-4 flex items-center;

  &--success {
    @apply text-secondary-700 bg-secondary-100;
  }
  &--info {
    @apply text-primary-1-100 bg-primary-100;
  }
  &--warning {
    @apply text-tertiary-700 bg-tertiary-100;
  }
  &--danger {
    @apply bg-red-200;
    & p {
      @apply text-primary-1-100;
    }
  }

  &__Body {
    @apply flex flex-col;
  }
}
</style>
